import React, { useEffect, useRef, useState } from "react";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import chatApi from "api/chat/chat.api";
import operatorApi from "api/operator/operator.api";
import { Operator } from "../AuthorPage/AuthorPage";
import useTyping from "./useTyping";
import Loading from "components/Loading/Loading";
import LoginModal from "../../containers/PageLogin/LoginModal";
import TypingMessage from "./TypingMessage";

interface Message {
  sender: string;
  text: string;
}

interface Conversation {
  _id: string;
  groupTitle: string;
  members: string[];
  createdAt: number;
  updatedAt: number;
  __v: number;
}

interface UserInboxProps {
  socketId: any;
}

interface ArrivalMessage {
  sender: string;
  text: string;
  createdAt: number;
}

const UserInbox: React.FC<UserInboxProps> = ({ socketId }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentChat, setCurrentChat] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [operators, setOperators] = useState<Operator[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [arrivalMessage, setArrivalMessage] = useState<ArrivalMessage | null>(null);
  const [typingUsers, setTypingUsers] = useState<string[]>([]);
	const { isTyping, startTyping, stopTyping, cancelTyping } = useTyping();
  const [onlineUsers, setOnlineUsers] = useState<any[]>([]);
  const [images, setImages] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);


  // Fetch conversations
  useEffect(() => {
    const getConversation = async () => {
      try {
        setLoading(true);
        const userId = user?._id;
        const response = await chatApi.getAllConversationUser({ userId });
        const conversationData = response.conversations;

        // Fetch operator info for each conversation
        const operatorPromises = conversationData.map((conversation: Conversation) => {
          const operatorId = conversation.members.find((member) => member !== userId);
          return operatorApi.getOperatorInfo({ operator: operatorId });
        });

        const operatorsResponse = await Promise.all(operatorPromises);
        const operatorData = operatorsResponse.map((res) => res.operator[0]); // Assuming `operator[0]` is the required data.

        setConversations(conversationData);
        setOperators(operatorData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching conversations or operators:", error);
      }
    };

    if (!user) {
      setLoading(false);
      setIsLoginModalOpen(true);
    } else {
      getConversation();
    }
    getConversation();
  }, [user]);

  // Real-time message listening
  useEffect(() => {
    socketId.on("getMessage", (data: any) => {
      console.log("getMessage", data);
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });

    socketId.on("startTypingMessage", (typingInfo: any) => {
      const user = typingInfo;
      setTypingUsers((users) => [...users, user]);
      // }
    });

    socketId.on("stopTypingMessage", (typingInfo: any) => {
      // console.log("stopTypingMessage", typingInfo)
      const user = typingInfo;
      setTypingUsers((users) => users.filter((u) => u !== user));
    });
  }, []);

  useEffect(() => {
    if (arrivalMessage) {
      console.log("Updated arrivalMessage", arrivalMessage);
      setMessages((prev) => [
        ...prev,
        {
          sender: arrivalMessage.sender,
          text: arrivalMessage.text,
        },
      ]);
    }
  }, [arrivalMessage]);

  useEffect(() => {
    const userId = user?._id;
    if (userId) {
      socketId.emit("addUser", userId);
      socketId.on("getUsers", (data: any) => {
        console.log("getUsers", data);
        setOnlineUsers(data);
      });
    }
  }, [user]);

  
  useEffect(() => {
    if (
      arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender)
    ) {
      console.log("arrivalMessage", arrivalMessage);
      setMessages((prev) => [
        ...prev,
        {
          sender: arrivalMessage.sender,
          text: arrivalMessage.text,
        },
      ]);
    }
  }, [arrivalMessage, currentChat]);

  // Scroll to the latest message
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // listenning an event
	const startTypingMessage = () => {
		if (!currentChat) return;
		const receiverId = currentChat.members.find((id) => id !== user?._id);
		// if (!socketId.current) return;
		socketId.emit("startTypingMessage", {
			senderId: user?._id,
			sendername: user?.displayName,
			receiverId,
		});
	};

  useEffect(() => {
    if (isTyping) {
      startTypingMessage();
    } else {
      stopTypingMessage();
    }
  }, [isTyping]);

	const stopTypingMessage = () => {
		if (!currentChat) return;
		const receiverId = currentChat.members.find((id) => id !== user?._id);
		socketId.emit("stopTypingMessage", {
			senderId: user?._id,
			sendername: user?.displayName,
			receiverId,
		});
	};

  // Send a message
  const sendMessageHandler = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!currentChat || (!newMessage.trim() && !images)) return; // Kiểm tra nếu có tin nhắn hoặc ảnh
  
    const receiverId = currentChat.members.find(
      (member) => member !== user?._id
    );
  
    // Gửi tin nhắn qua socket
    socketId.emit("sendMessage", {
      senderId: user?._id || "",
      receiverId,
      text: newMessage,
      images: images || null,
    });
  
    // Cập nhật danh sách tin nhắn hiển thị
    setMessages((prev) => [
      ...prev,
      { sender: user?._id || "", text: newMessage, images },
    ]);
  
    // Nếu có ảnh, gửi qua API
    if (images) {
      const formData = new FormData();
      formData.append("images", images);
      formData.append("sender", user?._id || "");
      formData.append("text", newMessage);
      formData.append("conversationId", currentChat._id || "");
  
      try {
        await chatApi.createNewMessage(formData);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  
    setNewMessage(""); // Reset tin nhắn
    setImages(null); // Xóa ảnh đã chọn
  };

	useEffect(() => {
		if (currentChat) {
			getAllMessages();
		}
	}, [currentChat]);

  const getAllMessages = async () => {
		try {
			const response = await chatApi.getAllMessages({ conversationId: currentChat?._id });
			setMessages(response.messages);
		} catch (error) {
			console.log(error);
		}
	}

  const onlineCheck = (chat: Conversation) => {
    const chatMembers = chat.members.find((member) => member !== user?._id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };

  const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(ev.target.value);
  
    if (!isTyping && ev.target.value) {
      startTyping();
    }
  
    if (isTyping && !ev.target.value) {
      stopTyping();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImages(file);
    }
  };

  const imageSendingHandler = async (e: any) => {
    const formData = new FormData();
  
    if (e) {
      formData.append("images", e);
    }
    if (user?._id) {
      formData.append("sender", user._id);
    }
    if (newMessage) {
      formData.append("text", newMessage);
    }
    if (currentChat?._id) {
      formData.append("conversationId", currentChat._id);
    }
  
    const receiverId = currentChat?.members.find((member) => member !== user?._id);
  
    socketId.emit("sendMessage", {
      senderId: user?._id || "",
      receiverId,
      images: e || "",
    });
  };

  useEffect(() => {
		if (typingUsers.length > 0) {
			console.log("typingUsers", typingUsers);
		}
	}, [typingUsers]);

  const typingIndicator = true && (
		<TypingMessage user={typingUsers[0]}></TypingMessage>
	);

  return (
    <div className="flex justify-center items-center bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 p-10">
      <div
        className="bg-white shadow-2xl rounded-lg overflow-hidden"
        style={{
          width: '100%', 
          maxWidth: '1000px',
          height: '600px',
        }}
      >
        <div className="border-b px-4 py-2 bg-red-500 text-white font-semibold text-lg">
          Messages
        </div>
        {!currentChat ? (
          <div className="overflow-y-auto" style={{ height: 'calc(100% - 50px)' }}>
            {loading && <Loading />}
            {conversations.map((item, index) => {
              const operatorId = item.members.find((member) => member !== user?._id);
              const operatorDetails = operators.find((op) => op._id === operatorId);
              const isOnline = onlineCheck(item);
          
              return (
                <div
                  key={index}
                  className="flex items-center p-4 border-b cursor-pointer hover:bg-gray-100 transition duration-300"
                  onClick={() => setCurrentChat(item)}
                >
                  <div className="relative">
                    <img
                      src={operatorDetails?.photo || "https://via.placeholder.com/40"}
                      alt={operatorDetails?.name || "Avatar"}
                      className="w-12 h-12 rounded-full border border-gray-200"
                    />
                    {isOnline && (
                      <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full"></span>
                    )}
                  </div>
                  <div className="ml-4 flex-1">
                    <p className="font-bold text-gray-800">{operatorDetails?.name || "Unknown"}</p>
                    <p className="text-sm text-gray-500 truncate">
                      Last message preview...
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col h-full">
            <div className="flex items-center p-4 bg-red-500 text-white">
              {(() => {
                const operatorId = currentChat.members.find(
                  (member) => member !== user?._id
                );
                const operatorDetails = operators.find((op) => op._id === operatorId);
  
                return (
                  <>
                    <img
                      src={operatorDetails?.photo || "https://via.placeholder.com/40"}
                      alt={operatorDetails?.name || "Avatar"}
                      className="w-12 h-12 rounded-full border border-white"
                    />
                    <p className="ml-4 font-semibold">{operatorDetails?.name || "Unknown"}</p>
                  </>
                );
              })()}
            </div>
  
            <div
              className="flex-1 overflow-y-auto p-4 bg-gray-50"
              style={{ maxHeight: 'calc(100% - 120px)' }}
            >
              {messages.map((msg, i) => (
                <div
                  key={i}
                  className={`flex ${
                    msg.sender === user?._id ? "justify-end" : "justify-start"
                  } mb-3`}
                >
                  <div
                    className={`px-4 py-2 rounded-lg max-w-xs shadow ${
                      msg.sender === user?._id
                        ? "bg-red-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                  >
                    <p>{msg.text}</p>
                  </div>
                </div>
              ))}
              <div ref={scrollRef}></div>
            </div>
            {typingIndicator}
            
            <form
              onSubmit={sendMessageHandler}
              className="flex p-4 bg-gray-100 border-t items-center"
            >
              {/* Nút tải ảnh */}
              <label
                htmlFor="image-upload"
                className="cursor-pointer flex items-center justify-center w-10 h-10 bg-gray-200 rounded-lg hover:bg-gray-300 transition mr-2"
              >
                📷
              </label>
              <input
                type="file"
                id="image-upload"
                className="hidden"
                accept="image/*"
                onChange={handleImageUpload} // Chỉ lưu ảnh vào state
              />

              {/* Hiển thị ảnh đã chọn */}
              {images && (
                <div className="mr-2">
                  <img
                    src={URL.createObjectURL(images)}
                    alt="Selected"
                    className="w-10 h-10 rounded-lg object-cover"
                  />
                </div>
              )}

              {/* Ô nhập tin nhắn */}
              <input
                value={newMessage}
                onChange={onInputChange}
                placeholder="Type your message..."
                className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />

              {/* Nút gửi */}
              <button className="ml-2 px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition">
                Send
              </button>
            </form>
          </div>
        )}
      </div>
      <LoginModal
          isOpen={isLoginModalOpen}
          closeModal={() => setIsLoginModalOpen(false)}
        />
    </div>
  );
};

export default UserInbox;
