import { publicClient, privateClient } from '../../configs/axiosConfig';
import chatEndpoints from './chatEndpoints';

const chatApi = {
  createNewConversation: async ({ groupTitle, userId, sellerId }) => {
    try {
      const response = await privateClient.post(chatEndpoints.createNewConversation, { groupTitle, userId, sellerId });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  getAllConversationUser: async ({userId}) => {
    try {
      const response = await privateClient.get(chatEndpoints.getAllConversationUser.replace(":userId", userId));
      return response.data;
    }
    catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  getAllMessages: async ({conversationId}) => {
    try {
      const response = await privateClient.get(chatEndpoints.getAllMessages.replace(":conversationId", conversationId));
      return response.data;
    }
    catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  createNewMessage: async ( message ) => {
    try {
      const response = await privateClient.post(chatEndpoints.createNewMessage,  message );
      return response.data;
    }
    catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  }
};

export default chatApi;