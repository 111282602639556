import { useEffect, useState } from "react";

const useTyping = () => {
  // Declaring the types of states
  const [isTyping, setIsTyping] = useState<boolean>(false);  // Track if the user is typing
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);  // Track if any key is pressed
  const [countdown, setCountdown] = useState<number>(5);  // Countdown timer for stopping typing

  // Start typing function
  const startTyping = () => {
    setIsKeyPressed(true);
    setCountdown(5);
    setIsTyping(true);
    console.log("start typing");
    console.log({ isTyping });
  };

  // Stop typing function
  const stopTyping = () => {
    setIsKeyPressed(false);
  };

  // Cancel typing function
  const cancelTyping = () => {
    setCountdown(0);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!isKeyPressed) {
      interval = setInterval(() => {
        setCountdown((c) => c - 1);
      }, 1000);
    } else if (isKeyPressed || countdown === 0) {
      clearInterval(interval!);
    }

    if (countdown === 0) {
      setIsTyping(false);
      console.log("isTyping finish");
    }

    // Clean up the interval on component unmount or dependencies change
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isKeyPressed, countdown]);

  return { isTyping, startTyping, stopTyping, cancelTyping };
};

export default useTyping;
