import React from "react";
import TypingAnimation from "./TypingAnimation";

import "./css/TypingMessage.css";

interface TypingMessageProps {
  user: string;
}

const TypingMessage: React.FC<TypingMessageProps> = ({ user }) => {
  return (
    <div className="flex justify-center items-center bg-gray-50">
      <TypingAnimation name={user} />
    </div>
  );
};

export default TypingMessage;