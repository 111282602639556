import React from "react";
import "./css/TypingAnimation.css";

interface TypingAnimationProps {
  name: string;
}

const TypingAnimation: React.FC<TypingAnimationProps> = ({ name }) => {
  return (
    <div className="typing-indicator flex justify-center items-center">
      <span className="text-sm">{name} is typing</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </div>
  );
};

export default TypingAnimation;